import React from "react"
import { Link } from "gatsby"
import Pricing from "../components/Pricing"
import ViceDropHeader from "../components/headers/vicedropHeader"
import Footer from "../components/footers/footer"
import SEO from "../components/seo"
import "./vicedrop.css"
import vicedrop_inverse from "../images/vicedrop_inverse.svg"
import mobile from "../images/phone.png"
import desktop from "../images/desktop_image.png"
import RecoverySteps from "@material-ui/icons/Assignment"
import Journal from "@material-ui/icons/MenuBook"
import Progress from "@material-ui/icons/TrendingUp"
import FightUrges from "@material-ui/icons/LocalHospital"
import Trends from "@material-ui/icons/Poll"
import Manage from "@material-ui/icons/Devices"
import { useMediaQuery } from "react-responsive"

const ViceDrop = () => {
  const [picture, setPicture] = React.useState("mobile")
  const isDesktop = useMediaQuery({ query: "(min-width: 768px)" })
  return (
    <>
      <ViceDropHeader />
      <SEO
        title="ViceDrop: Technology Addiction Recovery Tool"
        description="ViceDrop is powerful technology addiction recovery tool to be used in conjunction with our free recovery guide."
      />
      <main style={{ marginTop: "4.4em" }}>
        <section id="landing-intro">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <h1 id="intro-title">Crush your technology addiction with</h1>
            <img
              src={vicedrop_inverse}
              alt="ViceDrop logo"
              id="vicedrop-logo"
            />
            <h2>
              {" "}
              A powerful and convenient recovery tool to use alongside the
              <a href="https://guide.lifebeyondscreens.com/">
                {" "}
                free recovery guide
              </a>
              .
            </h2>
            <div id="intro-cta-buttons">
              <a
                className="inverted-button"
                target="_blank"
                id="demo"
                style={{
                  borderColor: "#34495e",
                  color: "#34495e",
                }}
                href="https://vicedrop.lifebeyondscreens.com/demo"
              >
                view demo now
              </a>
              <a
                className="button"
                style={{
                  borderColor: "#34495e",
                  background: "#34495e",
                }}
                href="#pricing"
              >
                Start free trial
              </a>
            </div>

            <div id="image-container">
              {isDesktop ? (
                <>
                  <img
                    src={mobile}
                    alt="ViceDrop on mobile"
                    id="vicedrop-mobile"
                  />
                  <img
                    src={desktop}
                    alt="ViceDrop on desktop"
                    id="vicedrop-desktop"
                  />
                </>
              ) : (
                <>
                  {picture === "mobile" ? (
                    <div id="options" style={{ margin: " -1em 0 -3em 0" }}>
                      <h5 style={{ opacity: "1" }}>Mobile</h5>
                      <h5 onClick={() => setPicture("desktop")}>Desktop</h5>
                    </div>
                  ) : (
                    <div id="options" style={{ margin: " -1em 0 -3em 0" }}>
                      <h5 onClick={() => setPicture("mobile")}>Mobile</h5>
                      <h5 style={{ opacity: "1" }}>Desktop</h5>
                    </div>
                  )}
                </>
              )}{" "}
            </div>
            {picture === "mobile" && !isDesktop ? (
              <img src={mobile} alt="ViceDrop on mobile" id="vicedrop-mobile" />
            ) : null}
            {picture === "desktop" && !isDesktop ? (
              <img
                src={desktop}
                alt="ViceDrop on desktop"
                id="vicedrop-desktop"
              />
            ) : null}
          </div>
        </section>
        <section id="intro-features">
          <h3> Make your recovery easier by having only one place to...</h3>
        </section>
        <section id="features">
          <div id="feature-grid">
            <div id="feature">
              <RecoverySteps id="feature-icon" />
              <h3 id="feature-title">Input recovery steps</h3>
              <p id="feature-description">
                Forget the notebook. ViceDrop allows you to input, modify, and
                reference all the recovery steps that you are taking following
                the
                <a href="https://guide.lifebeyondscreens.com/">
                  {" "}
                  recovery guide
                </a>
                , such as your reasons to quit, root cause, and triggers to use.
              </p>
            </div>

            <div id="feature">
              <Journal id="feature-icon" />
              <h3 id="feature-title">Journal experiences</h3>
              <div id="feature-description">
                <ol>
                  <li>
                    Reduce stress by tracking your day-to-day experiences with
                    the daily journal.
                  </li>
                  <li>
                    Log how you beat urges with the urge journal for future
                    reference and stats.
                  </li>
                  <li>
                    Learn from and prevent similar relapses with the relapse
                    journal.
                  </li>
                </ol>
              </div>
            </div>
            <div id="feature">
              <Progress id="feature-icon" />
              <h3 id="feature-title">Track progress</h3>
              <p id="feature-description">
                ViceDrop records and monitors your progress towards overcoming
                your technology vices. You can check your current abstinence
                time, relapses, and steps towards resolving your root cause of
                addiction.
              </p>
            </div>
            <div id="feature">
              <FightUrges id="feature-icon" />
              <h3 id="feature-title">Fight urges</h3>
              <p id="feature-description">
                The built in{" "}
                <a href="https://guide.lifebeyondscreens.com/address.html#the-fallback-urge-surfing">
                  urge surfing{" "}
                </a>
                timer is available for situations that arise where performing
                your replacement habit is infeasible or not possible. It
                includes a convenient option to make an urge journal entry with
                prefilled info after the timer is finished.
              </p>
            </div>
            <div id="feature">
              <Trends id="feature-icon" />
              <h3 id="feature-title">Learn from trends</h3>
              <p id="feature-description">
                The statistics page displays all kinds of important metrics,
                including your average urge strength and duration thanks to urge
                journal entries. With enough entries, it provides a predictable
                time frame for how long your urges should last.
              </p>
            </div>
            <div id="feature">
              <Manage id="feature-icon" />
              <h3 id="feature-title">Manage all your vices</h3>
              <p id="feature-description">
                Have more than one vice to deal with? No problem! ViceDrop's
                overview page provides a convenient place to manage all of your
                vices. Each vice has it's own dedicated dashboard for using all
                these features.
              </p>
            </div>
          </div>
        </section>
        <section id="view-demo">
          <h3 id="demo-title">Let actions speak louder than words.</h3>
          <a
            className="inverted-button"
            target="_blank"
            id="demo"
            style={{
              borderColor: "#283541",
              color: "#283541",
            }}
            href="https://vicedrop.lifebeyondscreens.com/demo"
          >
            View demo
          </a>
        </section>
        <section id="pricing">
          <h2>Make your recovery easier.</h2>
          <div id="pricing-subtitle">
            <h4>All at the price of one latte every month</h4>
            <div style={{ fontSize: "2.5rem", fontWeight: "800" }}>+</div>
            <h4>7-day trial on the house</h4>
          </div>

          <Pricing />
        </section>
        <section id="common-questions">
          <h2>Common Questions</h2>
          <div id="questions">
            <div id="question">
              <h4>
                Do I <i>need</i> ViceDrop to recover from my addiction?
              </h4>
              <p>
                Nope, it's just a tool I recommend to make recovery easier.
                <br />
                <br /> I made Life Beyond Screens with the purpose of helping as
                much people overcome technology addiction as possible. This is
                why the{" "}
                <a href="https://guide.lifebeyondscreens.com/">
                  recovery guide{" "}
                </a>
                and <a href="https://www.reddit.com/r/LifeBeyondScreens/">community </a>{" "}
                are and always will be free without any paywall. If you don't
                see any value in ViceDrop, feel free to just use the{" "}
                <a href="https://guide.lifebeyondscreens.com/">
                  recovery guide{" "}
                </a>{" "}
                by itself!
              </p>
            </div>
            <div id="question">
              <h4>Is this a smartphone, desktop, or browser app?</h4>
              <p>
                It's all of them!
                <br />
                <br />
                ViceDrop is a multiplatform PWA (
                <a
                  href="https://developer.mozilla.org/en-US/docs/Web/Progressive_web_apps"
                  target="_blank"
                >
                  progressive web app
                </a>
                ). This means you can use ViceDrop as an app on your browser
                without downloading anything or you can install ViceDrop as a
                desktop, tablet, or smartphone app. You can learn how to install
                a PWA{" "}
                <a
                  href="https://mobilesyrup.com/2020/05/24/how-install-progressive-web-app-pwa-android-ios-pc-mac/"
                  target="_blank"
                >
                  here
                </a>
                .
              </p>
            </div>

            <div id="question">
              <h4>How do I know my payment information is secure?</h4>
              <p>
                When you fill out your credit card information for starting the
                free ViceDrop trial you are redirected to a secure{" "}
                <a
                  href="https://en.wikipedia.org/wiki/Payment_Card_Industry_Data_Security_Standard"
                  target="_blank"
                >
                  PCI compliant
                </a>{" "}
                checkout page from a payment processor known as{" "}
                <a href="https://stripe.com/" target="_blank">
                  Stripe
                </a>
                . Stripe is well established and used by top companies like
                Google and Amazon.
              </p>
            </div>
            <div id="question">
              <h4>How does the 7-day free trial work?</h4>
              <p>
                After filling out your credit card information, you are given an
                email with a special link to redeem your ViceDrop account with
                full access. If you finished the trial period, you are
                automatically billed for the payment plan of your choice. If you
                didn't find ViceDrop beneficial, you can cancel your trial
                before being charged{" "}
                <a
                  href="https://vicedrop.lifebeyondscreens.com/settings"
                  target="_blank"
                >
                  here
                </a>
                . Don't worry, we will remind you before the trial is over!
              </p>
            </div>
            <div id="question">
              <h4>Do you refund accidental payments?</h4>
              <p>
                Of course!
                <br />
                <br />
                If you got charged for accidentally forgetting to cancel your
                trial or subscription, we'll fully refund you for whatever your
                subscription charge was. See our{" "}
                <Link to="/terms-of-service/#refund-policy">
                  refund policy
                </Link>{" "}
                for more information.
              </p>
            </div>
            <div id="question">
              <h4>What happens to my account when I cancel a subscription?</h4>
              <p>
                Your subscription status will continue to be active until the
                end of your billing cycle. After that, your account subscription
                status will be marked as inactive but you will still have read
                and delete privileges.
                <br />
                <br />
                We don't believe it's fair for inactive members to have to pay
                for monthly fees just to view the last time they used their vice
                or to reflect upon journal entries. You worked hard for your
                progress, the least we can do is provide you with a place to
                showcase how far you've come. See our{" "}
                <Link to="/terms-of-service/#cancellation-policy">
                  cancellation policy
                </Link>{" "}
                for more information on the permissions you do and do not have.
              </p>
            </div>
            <div id="question">
              <h4>Will you increase my subscription price over time?</h4>
              <p>
                Never.
                <br />
                <br />
                While we may increase our prices whenever it's deemed just,
                customers will always be locked in to the price plan they signed
                up with. This of course, is voided if you cancel your
                subscription using an outdated/grandfathered price plan.
              </p>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  )
}

export default ViceDrop
