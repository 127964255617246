import React, { useState } from "react"
import "./Pricing.css"
import { useStripe } from "@stripe/react-stripe-js"

export default function Pricing() {
  const stripe = useStripe()
  const [billingCycle, setBillingCycle] = useState("monthly")

  const newPurchase = async e => {
    if (billingCycle === "monthly") {
      let session = null
      const res = await fetch(
        `${process.env.GATSBY_URL}/auth/stripe_new_purchase_monthly/`,
        {
          method: "POST",
        }
      )
      const data = await res.json()
      session = data.id
      stripe
        .redirectToCheckout({
          sessionId: session,
        })
        .then(function (result) {
          console.log(result.error.message)
        })
    } else {
      let session = null
      const res = await fetch(
        `${process.env.GATSBY_URL}/auth/stripe_new_purchase_yearly/`,
        {
          method: "POST",
        }
      )
      const data = await res.json()
      session = data.id
      stripe
        .redirectToCheckout({
          sessionId: session,
        })
        .then(function (result) {
          console.log(result.error.message)
        })
    }
  }
  return (
    <>
      {billingCycle === "monthly" ? (
        <div id="options">
          <h5 style={{ opacity: "1" }}>Monthly</h5>
          <div id="discount-balloon-container" style={{ marginTop: "-0.3em" }}>
            <div id="discount-balloon-tip"></div>
            <div id="discount-balloon">
              <div id="discount-balloon-text"></div>Save 20%
            </div>
          </div>
          <h5 onClick={() => setBillingCycle("yearly")}>Yearly</h5>
        </div>
      ) : (
        <div id="options">
          <h5 onClick={() => setBillingCycle("monthly")}>Monthly</h5>
          <div id="discount-balloon-container" style={{ marginTop: "-0.3em" }}>
            <div id="discount-balloon-tip"></div>
            <div id="discount-balloon">
              <div id="discount-balloon-text"></div>Save 20%
            </div>
          </div>
          <h5 style={{ opacity: "1" }}>Yearly</h5>
        </div>
      )}

      <div id="pricing-container">
        {billingCycle === "monthly" ? (
          <div id="price">
            <div id="dollar-sign">$</div>
            <div id="price-amount">5</div>
            <div id="billing-cycle">/ mo</div>
          </div>
        ) : (
          <div id="price">
            <div id="dollar-sign">$</div>
            <div id="price-amount">48</div>
            <div id="billing-cycle">/ yr</div>
          </div>
        )}

        <div id="price-features">
          <ul>
            <li>7-day free trial</li>
            <li>No hidden fees</li>
            <li>Cancel anytime</li>
            <li>
              Secure with{" "}
              <a href="https://stripe.com/" target="_blank">
                Stripe
              </a>
            </li>
          </ul>
        </div>
        <div id="price-button">
          <a
            className="button"
            target="_blank"
            id="demo"
            style={{
              backgroundColor: "#283541",
              border: "unset",
              opacity: "1",
            }}
            onClick={() => newPurchase()}
          >
            Start free trial
          </a>
        </div>
      </div>
    </>
  )
}
