import { Link } from "gatsby"
import React from "react"
import { useMediaQuery } from "react-responsive"
import { slide as Menu } from "react-burger-menu"
import lbslogo from "../../images/lbs.png"
import "./header.css"

const ViceDropHeader = () => {
  const isDesktop = useMediaQuery({ query: "(min-width: 768px)" })

  return (
    <>
      {isDesktop ? (
        <header id="vicedrop-header">
          <nav id="header-nav">
            <ul id="begin-links">
              <Link to="/">
                <img id="logo" src={lbslogo} alt="Life Beyond Screens Logo" />
              </Link>
              <li id="link">
                <a href="#pricing">Pricing</a>
              </li>{" "}
              <li id="link">
                <a href="#features">Features</a>
              </li>{" "}
              <li id="link">
                {" "}
                <a href="#common-questions">FAQ</a>
              </li>{" "}
            </ul>
            <ul id="end-links">
              <li id="link">
                <Link to="https://vicedrop.lifebeyondscreens.com/demo">
                  View Demo
                </Link>
              </li>{" "}
              <a href="#pricing">
                <li id="link-highlight">
                  {" "}
                  <div>Start Trial</div>
                </li>{" "}
              </a>
            </ul>
          </nav>
        </header>
      ) : (
        <header id="vicedrop-header">
          <Link to="/">
            <img id="logo" src={lbslogo} alt="Life Beyond Screens Logo" />
          </Link>
          <Menu
            customCrossIcon={false}
            burgerBarClassName={"bar-white"}
            burgerButtonClassName={"button-right"}
            itemListClassName={"items-left"}
            right
          >
            <nav id="header-nav">
              <ul id="links">
                <li id="link">
                  <Link to="/">Home</Link>
                </li>
                <li id="link">
                  <a href="#pricing">Pricing</a>
                </li>
                <li id="link">
                  <a href="#features">Features</a>
                </li>
                <li id="link">
                  <a href="#common-questions">FAQ</a>
                </li>
                <li id="link">
                  <Link to="https://vicedrop.lifebeyondscreens.com/demo">
                    View Demo
                  </Link>
                </li>{" "}
                <a href="#pricing">
                  <li id="link-highlight">
                    <div>Start Trial</div>
                  </li>{" "}
                </a>
              </ul>
            </nav>
          </Menu>
        </header>
      )}
    </>
  )
}

export default ViceDropHeader
